import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
} from '@mui/material'

import { showSnackbar } from '@context/SnackbarContext'
import { useParams, useLocation } from 'react-router-dom'
import moment from 'moment'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useSelector, useDispatch } from 'react-redux'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import WebService from '@util/webService'
import { dataUrlToFile, getAddress, intersect } from '@util/helper'
import EmailOutlined from '@mui/icons-material/EmailOutlined'
import HomeOutlined from '@mui/icons-material/HomeOutlined'
import { comma } from '@util/helper'
import NumericTextField from '@components/NumericTextField'
import { createOrder } from '@pages/common/common'
import axios from 'axios'
import useAlert from '@hooks/useAlert'
import AttachFileOutlined from '@mui/icons-material/AttachFileOutlined'
import { billiard, attrGuide } from '@util/string'
import {
  companyPhone,
  companyName,
  companyCeo,
  companyEmail,
  companyNum,
  companyAddr,
  companyMosan,
  termsOfService,
  providingInfo,
} from '@util/string'
import SignatureCanvas from 'react-signature-canvas'
import { MAX_FILE_SIZE } from '@util/constant'
import { useNavigate } from 'react-router-dom'
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined'
import PaymentPopup from '@components/PaymentPopup'
import { nanoid } from 'nanoid'
import { setLoading } from '@context/LoadingContext'

const OrderIndexPage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const codes = useSelector((state) => state.codeReducer)
  const account = useSelector((state) => state.accountReducer)
  const location = useLocation()
  const docs = location.state?.docs ?? []
  const [order, set_order] = useState(createOrder(account))
  const curLocationRef = useRef(location)
  const recvRef = useRef(null)
  const addrRef = useRef(null)
  const docCodes = codes.filter((r) => r.codeGroupId === 'D001')
  const semuCodes = codes.filter((r) => r.codeGroupId === 'D001' && r.code.startsWith('DS'))
  const restCodes = codes.filter((r) => r.codeGroupId === 'D001' && r.code.startsWith('DG'))
  const dialCodes = codes.filter((r) => r.codeGroupId === 'C003' && r.code)
  const [sum, set_sum] = useState(0)
  const { alert, renderAlert } = useAlert()
  const [file, set_file] = useState(null)
  const sigRef = useRef(null)
  const navigate = useNavigate()
  const semuArray = intersect(
    semuCodes.map((r) => r.code),
    docs.map((r) => r.docCode)
  )
  const [preparing, set_preparing] = useState(false)
  const [recvAmount, set_recvAmount] = useState(0)
  const [totQty, set_totQty] = useState(0)
  const [signeed, set_signeed] = useState(semuArray.length > 0 ? true : false)
  const dgRef = useRef(null)
  const [agency, set_agency] = useState({})

  const [clientKey, set_clientKey] = useState('')

  const fetchAgency = async () => {
    console.log('fetchAgency....')
    const url = '/agency'
    const resp = await WebService.get(url)
    if(resp.repCode !== 'ack') {
      alert(resp.repMessage) 
      return
    }

    console.log('resp=>', resp)
    const repMessage = JSON.parse(resp.repMessage)
    const records = JSON.parse(repMessage.records)
    console.log('agency=>', agency)
    if(records.length > 0) {
      set_agency(records[0])
    }
  }

  const onLoad = async () => {
    if (!account?.user?.id) {
      navigate('/login')
      return
    }

    await fetchAgency()

    const url = `/order/pg/key`
    const resp = await WebService.get(url)
    if (resp.repCode !== 'ack') {
      alert('결제키를 가져오는데 실패하였습니다.')
      return
    }
    const repMessage = JSON.parse(resp.repMessage)
    const clientKey = repMessage.clientKey
    set_clientKey(clientKey)

  }

  let orderTitle = ''
  if (docs.length > 0) {
    orderTitle = docs[0].name
  }
  if (docs.length > 1) {
    orderTitle += `외 $${docs.length - 1}건`
  }

  console.log('nanoid=>', nanoid())

  useEffect(() => {
    if (curLocationRef.current.key !== location.key) {
      curLocationRef.current = location
      onLoad()
    }
  }, [location])

  useEffect(() => {
    onLoad()
  }, [])

  const onChangeFile = (e) => {
    const file = e.target.files[0]
    if (file.size > MAX_FILE_SIZE * 3) {
      e.target.value = ''
      alert('파일 크기는 3MB 이하만 가능합니다.')
      return
    }
    const fileType = file.type;
    if (fileType !== 'image/jpeg' && fileType !== 'image/png') {
      e.target.value = ''
      alert('JPG 또는 PNG 파일만 첨부가능합니다.');

      return;
    }
    set_file(file)
  }

  useEffect(() => {
    const docAmount = docs.reduce((acc, cur) => {
      return acc + cur.amount
    }, 0)



    const recvSum = order.recvs.reduce((acc, cur) => {
      return acc + Number(cur.note1)
    }, 0)

    const totQty = docs.reduce((acc, cur) => {
     return acc + cur.qty
     }, 0)

    const recvAmount = recvSum * totQty
    set_totQty(totQty)

    const amount = docAmount + recvAmount
    set_order({ ...order, amount: amount})


  }, [docs, order.recvs])

  const onAddr = (data) => {
    const addr = getAddress(data)
    set_order({ ...order, recvAddr1: addr })
  }

  const onRecv = (checkList) => {
    const new_data = { ...order }
    new_data.recvs = checkList
    new_data.recvCodes = checkList.map((r) => r.code).join(',')
    set_order(new_data)
  }

  const validate = () => {
    if (!order.name) {
      showSnackbar('이름 를(을) 입력해 주세요', theme.palette.warning.dark)
      return
    }

    if (!order.phoneDial) {
      showSnackbar('전화번호 국번 를(을) 입력해 주세요.', theme.palette.warning.dark)
      return
    }
    if (!order.phone1) {
      showSnackbar('전화번호 앞자리 를(을) 입력해 주세요.', theme.palette.warning.dark)
      return
    }
    if (!order.phone2) {
      showSnackbar('전화번호 뒷자리 를(을) 입력해 주세요.', theme.palette.warning.dark)
      return
    }
    const phone = `${order.phoneDial}${order.phone1}${order.phone2}`
    if (phone.length !== 11) {
      showSnackbar('전화번호를  정확히  입력해 주세요.', theme.palette.warning.dark)
      return
    }
    if (!account?.user?.id) {
      if (!order.password) {
        showSnackbar('패스워드  를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
      if (!order.password) {
        showSnackbar('패스워드확인  를(을) 입력해 주세요', theme.palette.warning.dark)
        return
      }
      if (order.password != order.password2) {
        showSnackbar('패스워드가 동일하지 않습니다.', theme.palette.warning.dark)
        return
      }
    }

    if (order.recvs.length === 0) {
      showSnackbar('수령방법 를(을) 입력해 주세요', theme.palette.warning.dark)
      return
    }

    const recvs = order.recvs.map((r) => r.code)
    if (recvs.includes('R002')) {
      if (!order.email) {
        showSnackbar('이메일 를(을) 입력해 주세요', theme.palette.warning.dark)
        return
      }
    }
    if (recvs.includes('R004')) {
      if (!order.faxDial) {
        showSnackbar('팩스국번 를(을) 입력해 주세요', theme.palette.warning.dark)
        return
      }
      if (!order.fax1) {
        showSnackbar('팩스앞자리 를(을) 입력해 주세요', theme.palette.warning.dark)
        return
      } else {
        if (order.fax2.length < 3) {
          showSnackbar('팩스앞자리 를(을) 입력해 주세요', theme.palette.warning.dark)
          return
        }
      }
      if (!order.fax2) {
        showSnackbar('팩스뒷자리 를(을) 입력해 주세요', theme.palette.warning.dark)
        return
      } else {
        if (order.fax2.length < 4) {
          showSnackbar('팩스뒷자리 를(을) 입력해 주세요', theme.palette.warning.dark)
          return
        }
      }
      order.fax = `${order.faxDial}${order.fax1}${order.fax2}`
    }
    if (recvs.includes('R005')) {
      if (!order.recvAddr1) {
        showSnackbar('기본주소 를(을) 입력해 주세요', theme.palette.warning.dark)
        return
      }
      if (!order.recvAddr2) {
        showSnackbar('상세주소 를(을) 입력해 주세요', theme.palette.warning.dark)
        return
      }
    }

    if (signeed) {
      if (!file) {
        showSnackbar('신분증 등의 사본을 첨부해 주세요.', theme.palette.warning.dark)
        return
      }
      if (sigRef.current.isEmpty()) {
        showSnackbar('서명란에 사인해 주세요.', theme.palette.warning.dark)
        return
      }
    }
    // docs.map(r => r.docCode)
    if (docs.length == 0) {
      showSnackbar('신청정보가 없습니다.', theme.palette.warning.dark)
      return
    }

    if (!order.depositor) {
      showSnackbar('입금자명(결제자명) 를(을) 입력해 주세요', theme.palette.warning.dark)
      return
    }

    return true
  }

  const onWait= async() => {
    set_preparing(true)
    setLoading(true)
    const ret = await onPrepare('SA00')
    setLoading(false)
    set_preparing(false)
    if(ret) {
      navigate('/order/wait', {
        state:{
          amount: order.amount
        }
      }) 
    } 
  }

  const onPrepare = async (statCode) => {
    const ret = validate()
    if (!ret) {
      return
    }

    const url = '/order'
    docs.forEach(item => {
      item.statCode = statCode
    }) 

    const data = {
      order: {...order, statCode: statCode},
      docs: docs,
    }

    console.log('onPrepare data=>', JSON.stringify(data))
    const params = {}
    const formData = new FormData()

    if (signeed) {
      const sigFile = dataUrlToFile(
        sigRef.current.getTrimmedCanvas().toDataURL('image/png'),
        'my_service_signature.png'
      )

      // formData.append('files', file)
      const f = new File([file], file.name, { type: file.type }) 
      formData.append(`files`, f)
      formData.append('files', sigFile)
    }

    const jsonData = JSON.stringify(data)
    formData.append('body', new Blob([jsonData], { type: 'application/json' }))

    const resp = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: params,
    })

    if (resp.data.repCode !== 'ack') {
      alert(resp.data.repMessage)
      return
    }
    return true
  }


  return (
    <div style={{ paddingTop: isMobile ? 50 : 100 }}>
      {renderAlert()}
      <PaymentPopup
        ref={dgRef}
        order={order}
        orderTitle={orderTitle}
        windowWith='sm'
        title='민원서비스 결제'
        onPrepare={onPrepare}
        clientKey={clientKey}
      />
      <AddrPopup ref={addrRef} windowWith='sm' title='수령지 선택' callback={onAddr} />
      <RecvPopup ref={recvRef} windowWith='sm' title='수령방법 선택' callback={onRecv} />
      <Container maxWidth='sm'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <IconButton
              style={{ backgroundColor: '#fafafa' }}
              onClick={() => {
                const pack = location.state?.pack
                if (location.state?.pack) {
                  if (pack) {
                    navigate(pack.url, {
                      replace: true,
                      state: {
                        pack: pack,
                      },
                    })
                  }
                } else {
                  navigate(-1)
                }
              }}
            >
              <ArrowBackOutlined />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5' style={{ fontWeight: 'bold' }}>
              주문/결제
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6' style={{ fontWeight: 'bold' }}>
              신청인정보
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              label='이름'
              inputProps={{ maxLength: 20 }}
              defaultValue={order.name}
              onChange={(e) => {
                order.name = e.target.value
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={2} md={2}>
                <NumericTextField fullWidth maxWidth={4} value={order.phoneDial} label='국번' />
              </Grid>
              <Grid item xs={5} md={5}>
                <NumericTextField
                  fullWidth
                  required
                  maxWidth={4}
                  label='전화앞자리'
                  maxLength={4}
                  value={order.phone1}
                  onChange={(e) => set_order({ ...order, phone1: e })}
                />
              </Grid>
              <Grid item xs={5} md={5}>
                <NumericTextField
                  fullWidth
                  required
                  maxWidth={4}
                  label='전화뒷자리'
                  maxLength={4}
                  value={order.phone2}
                  onChange={(e) => set_order({ ...order, phone2: e })}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid item xs={12}> */}
          {/*   <TextField */}
          {/*     fullWidth */}
          {/*     required */}
          {/*     label='패스워드' */}
          {/*     type='password' */}
          {/*     inputProps={{ maxLength: 20 }} */}
          {/*     defaultValue={order.password} */}
          {/*     onChange={(e) => { */}
          {/*       order.password = e.target.value */}
          {/*     }} */}
          {/*   /> */}
          {/* </Grid> */}
          {/**/}
          {/* <Grid item xs={12}> */}
          {/*   <TextField */}
          {/*     fullWidth */}
          {/*     required */}
          {/*     label='패스워드확인' */}
          {/*     type='password' */}
          {/*     inputProps={{ maxLength: 20 }} */}
          {/*     defaultValue={order.password2} */}
          {/*     onChange={(e) => { */}
          {/*       order.password2 = e.target.value */}
          {/*     }} */}
          {/*   /> */}
          {/* </Grid> */}
          <Grid item xs={12}>
            <Typography variant='h6' style={{ fontWeight: 'bold' }}>
              주문정보
            </Typography>
          </Grid>
          {docs.map((item, idx) => (
            <Grid item xs={12} key={idx}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography>{docCodes.find((r) => r.code === item.docCode)?.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                  {item.qty} 매
                </Grid>
                <Grid item xs={3}>
                  {comma(item.amount)} 원
                </Grid>
              </Grid>
            </Grid>
          ))}

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                  수령방법
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button
                  size='sm'
                  fullWidth
                  startIcon={<EmailOutlined />}
                  onClick={() => {
                    recvRef.current.open()
                  }}
                >
                  수령방법 선택
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {order.recvs.length > 0 ? (
              order.recvs.map((item) => (
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography>{item.name}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>{totQty}매 </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>{comma(item.note1 * totQty)} 원</Typography>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Typography color={theme.palette.secondary.dark}>수령방법을 선택해 주세요.</Typography>
            )}
          </Grid>
          {order.recvs.map((r) => r.code).includes('R002') ? (
            <Grid item xs={12}>
              <TextField
                fullWidth
                label='이메일'
                defaultValue={order.email}
                onChange={(e) => {
                  order.email = e.target.value
                }}
              />
            </Grid>
          ) : null}
          {order.recvs.map((r) => r.code).includes('R004') ? (
            <Grid item xs={12}>
              <Grid container spacing={1} style={gs.alignCenter}>
                <Grid item xs={2} md={2}>
                  <FormControl fullWidth>
                    <InputLabel id='dialCode'>국번</InputLabel>
                    <Select
                      labelId='dialCode'
                      required
                      label='국번'
                      value={order.faxDial}
                      onChange={async (e) => {
                        order.faxDial = e.target.value
                      }}
                    >
                      {dialCodes.map((l, index) => {
                        return (
                          <MenuItem key={index} value={l.code}>
                            {l.code}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5} md={5}>
                  <NumericTextField
                    fullWidth
                    maxLength={4}
                    label='팩스앞자리'
                    value={order.fax1}
                    onChange={(e) => {
                      set_order({ ...order, fax1: e })
                    }}
                  />
                </Grid>
                <Grid item xs={5} md={5}>
                  <NumericTextField
                    fullWidth
                    maxLength={4}
                    label='팩스뒷자리'
                    value={order.fax2}
                    onChange={(e) => {
                      set_order({ ...order, fax2: e })
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {order.recvs.map((r) => r.code).includes('R005') ? (
            <Grid item xs={12}>
              <Grid container spacing={1} style={gs.alignCenter}>
                <Grid item xs={12} md={9}>
                  <TextField
                    fullWidth
                    label='기본주소'
                    disabled={true}
                    value={order.recvAddr1}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    label='상세주소'
                    defaultValue={order.addr2}
                    onChange={(e) => {
                      order.recvAddr2 = e.target.value
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button
                    size='md'
                    fullWidth
                    startIcon={<HomeOutlined />}
                    onClick={() => {
                      addrRef.current.open()
                    }}
                  >
                    주소 선택
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Grid container spacing={1} style={gs.alignCenter}>
              <Grid item xs={6}></Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                  총요금
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant='h5' style={{ fontWeight: 'bold' }}>
                  {comma(order.amount)} 원
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {signeed ? (
            <>
              <Grid item xs={12}>
                <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                  위임정보
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant='standard'
                      type='text'
                      value={file ? file.name : null}
                      fullWidth
                      label='신분증등 사본'
                      InputLabelProps={{ shrink: file }}
                      InputProps={{
                        endAdornment: (
                          <IconButton component='label'>
                            <AttachFileOutlined />
                            <input
                              styles={{ display: 'none' }}
                              type='file'
                              accept='.jpg, .png, .jpeg'
                              hidden
                              onChange={(e) => onChangeFile(e)}
                            />
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs md>
                    <Typography style={{ whiteSpace: 'pre-line', fontSize: '0.8em' }}>{attrGuide}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Typography>위임받는 사람: {agency.name}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography>주민등록번호: {agency.civilNum?.take(6)} - {agency.civilNum?.takeRight(7)}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography>납세자(신청인)과의 관계: 세무대리인</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography>전화번호(휴대폰): {agency.phoneDial} - {agency.phone1} - {agency.phone2}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Grid item xs={12}>
                    서명란
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SignatureCanvas
                      clearOnResize={false}
                      ref={sigRef}

                      canvasProps={{
                        style: { backgroundColor: '#fafafa', border: '1px solid silver', width:300, height:150 },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      onClick={(e) => {
                        sigRef.current?.clear()
                      }}
                    >
                      서명지우기
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null}

          <Grid item xs={12}>
            <Grid container spacing={1} style={{alignItems:'center'}}>
              <Grid item xs={4}>
                입금자명/결제자명
              </Grid>
              <Grid item xs={8}>
                <TextField fullWidth 
                  required
                  label='입금자명/결제자명'
                  defaultValue={order.depositor} 
                  onChange={e => {
                    order.depositor = e.target.value
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant='contained'
              size='large'
              disabled={!clientKey || preparing}
              onClick={() => {
                const ret = validate()
                if (ret) {
                  dgRef.current.open()
                }
              }}
            >
              즉시 결제하기
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant='contained'
              size='large'
              disabled={preparing}
              onClick={() => {
                onWait()
              }}
            >
              무통장 입금 결제하기 
            </Button>
          </Grid>
          {/* <Grid item xs={12}> */}
          {/*   <Button fullWidth variant='contained' onClick={onSubmit}> */}
          {/*     결제하기 */}
          {/*   </Button> */}
          {/* </Grid> */}
        </Grid>
      </Container>
    </div>
  )
}

export default OrderIndexPage
