import React, { useState, useEffect, useMemo, Fragment, useRef, forwardRef, useImperativeHandle } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
} from '@mui/material'
import DaumPostcodeEmbed, { useDaumPostcodePopup } from 'react-daum-postcode'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import Agmt from '@components/Agmt'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import Labeller from '@components/Labeller'
import { alignProperty } from '@mui/material/styles/cssUtils'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import 'dayjs/locale/ko'
import { useSelector, useDispatch } from 'react-redux'
import MinwonInput from '@components/MinwonInput'
import AgmtOffice from '@components/AgmtOffice'
import { Form, useParams } from 'react-router-dom'
import { doc } from 'prettier'
import { comma, getAddress } from '@src/util/helper'
import NumericTextField from '@components/NumericTextField'
import { take, takeRight, drop } from 'lodash'
import AttachFileOutlined from '@mui/icons-material/AttachFileOutlined'
import moment from 'moment'
import { MAX_FILE_SIZE } from '@util/constant'
import { billiard, attrGuide } from '@util/string'
import SignatureCanvas from 'react-signature-canvas'
import { useLocation } from 'react-router-dom'
import { needPrfTerm } from './semu.util'
import useAmount from '@hooks/useAmount'
import accountReducer from '@redux/reducers/accountReducer'
import { useExpanded } from 'react-table'
const alignCenter = {
  display: 'flex',
  alignItems: 'center',
}

const tsx = 3

const SemuPartEdit = ({ record, set_record, docCode }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const codes = useSelector((state) => state.codeReducer)
  const dgRef = useRef()
  const dgRef2 = useRef()
  const recvRef = useRef()
  const location = useLocation()
  const account = useSelector((state) => state.accountReducer)
  const curLocationRef = useRef(location)

  const docCodes = codes.filter((r) => r.codeGroupId === 'D001')
  const sepCodes = codes.filter((r) => r.codeGroupId === 'D009')
  const mockCodes = codes
    .filter((r) => r.codeGroupId === 'D010')
    .filter((r) => (docCode === 'DS06' ? r.code.startsWith('KL') : true))
  const aimCodes = codes
    .filter((r) => r.codeGroupId === 'D003')
    .filter((r) => (docCode == 'DS10' ? r.code.startsWith('L') : r.code.startsWith('A')))
  const bizCodes = codes.filter((r) => r.codeGroupId === 'D004')
  const estCodes = codes.filter((r) => r.codeGroupId === 'D006')
  const factCodes = codes.filter((r) => r.codeGroupId === 'D005')
  const sssCodes =
    docCode === 'DS04'
      ? sepCodes.filter((r) => r.code === 'S002' || r.code === 'S003')
      : record.docCode === 'DS02' || record.docCode === 'DS03' || record.docCode === 'DS07' || record.docCode === 'DS09'
        ? sepCodes.filter((r) => r.code === 'S002' || r.code === 'S003')
        : sepCodes
  const [file, set_file] = useState(null)
  const sigRef = useRef(null)
  const onlyForBiz = ['DS02', 'DS03']
  const { price, amount } = useAmount(record)
  const onChangeFile = (e) => {
    const file = e.target.files[0]
    if (file.size > MAX_FILE_SIZE * 3) {
      alert('파일 크기는 3MB 이하만 가능합니다.')
      return
    }
    record.file = file
    set_file(file)
  }

  useEffect(() => {
    console.log('SemuPartEdit mounted.')
    if (docCode === 'DS04') {
      set_record({ ...record, sepCode: 'S003' })
    }
  }, [docCode])

  useEffect(() => {
    // if(curLocationRef.current.key !== location.key) {
    //   curLocationRef.current = location
    //   console.log('location changed.....')
    // }
  }, [location])

  const DocSep = () => {
    console.log('docCode=>', docCode)

    console.log('sssCodes=>', sssCodes)

    // useEffect(()=> {
    //   if(docCode == 'DS04') {
    //     set_record({...record, sepCode:'S003'})
    //   }
    // },[docCode])

    return (
      <MinwonInput
        label='대상구분'
        must={true}
        component={
          <RadioGroup
            row
            value={record.sepCode}
            onChange={(e) => {
              set_record({ ...record, sepCode: e.target.value })
            }}
          >
            {sssCodes.map((code, idx) => (
              <FormControlLabel key={idx} value={code.code} label={code.name} control={<Radio />} />
            ))}
          </RadioGroup>
        }
      />
    )
  }

  return (
    <>
      <AddrPopup
        ref={dgRef}
        title={'주소검색'}
        windowWidth='sm'
        callback={(data) => {
          const addr = getAddress(data, record.hanYn === 'Y', true)
          console.log('set addr =>', addr)
          set_record({ ...record, addr1: addr })
        }}
      />
      <AddrPopup
        ref={dgRef2}
        title={'주소검색'}
        windowWidth='sm'
        callback={(data) => {
          const addr = getAddress(data, record.hanYn === 'Y', true)
          set_record({ ...record, trustAddr1: addr })
        }}
      />
      <RecvPopup ref={recvRef} title={'수령방법'} windowWidth='sm' callback={(recv) => {}} />

      <Container maxWidth='lg'>
        <Grid container spacing={1}>
          <MinwonInput
            label='사업자구분'
            must={true}
            component={
              <Grid container>
                <Grid item xs={12}>
                  <Typography color={theme.palette.secondary.main} style={{ margin: 5 }}>
                    {billiard} 법인사업자,개인사업자 민원서류신청시 신청자는 대표이사, 대표자 본인만 가능합니다. 타인접수시 발급불가 사유로 환불불가 합니다.
                  </Typography>
                </Grid>  

                {
                  docCode == 'DS04' ? (
                    <Grid item xs={12}>
                      <Typography color={theme.palette.secondary.main} style={{ margin: 5 }}>
                        {billiard} 개인사업자의 경우 복식부기만 가능합니다.
                      </Typography>
                    </Grid>  
                  ) : null
                }

                {
                  (docCode == 'DS06' || docCode == 'DS10') ? (
                    <Grid item xs={12}>
                      <Typography color={theme.palette.secondary.main} style={{ margin: 5 }}>
                        {billiard} 완납증명(체납없음증명) 신청시 체납을 원인으로 발급불가 사유인 경우 환불불가 합니다. 
                      </Typography>
                    </Grid>  
                  ) : null
                }
                <Grid item xs={12}>
                  <RadioGroup
                    row
                    value={record.sepCode}
                    onChange={(e) => {
                      set_record({ ...record, sepCode: e.target.value })
                    }}
                  >
                    {sssCodes.map((code, idx) => (
                      <FormControlLabel key={idx} value={code.code} label={code.name} control={<Radio />} />
                    ))}
                  </RadioGroup>
                </Grid>
              </Grid>
            }
          />
          <MinwonInput
            label='한영선택'
            must={true}
            component={
              <RadioGroup
                row
                value={record.hanYn}
                onChange={(e) => {
                  set_record({ ...record, hanYn: e.target.value })
                }}
              >
                {[
                  { code: 'Y', name: '한글' },
                  { code: 'N', name: '영문' },
                ].map((code, idx) => (
                  <FormControlLabel key={idx} value={code.code} label={code.name} control={<Radio />} />
                ))}
              </RadioGroup>
            }
          />

          <MinwonInput
            label='신청인성명'
            must={true}
            component={
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    disabled={account?.user?.id}
                    defaultValue={record.name}
                    onChange={(e) => {
                      record.name = e.target.value
                    }}
                  />
                </Grid>
              </Grid>
            }
          />
          {record.hanYn == 'Y' ? null : (
            <MinwonInput
              label='영문성명'
              must={true}
              component={
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      defaultValue={record.engName}
                      onChange={(e) => {
                        record.engName = e.target.value
                      }}
                    />
                  </Grid>
                </Grid>
              }
            />
          )}
          {docCode === 'DS10' && record.sepCode === 'S003' ? (
            <MinwonInput
              label='법인등록번호'
              must={true}
              component={
                <Grid container style={alignCenter}>
                  <Grid item xs={5}>
                    <NumericTextField
                      fullWidth
                      maxLength={6}
                      value={record.corpNum1}
                      onChange={(e) => {
                        console.log('corpNum changed....')
                        set_record({ ...record, corpNum1: e })
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography textAlign={'center'}>-</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <NumericTextField
                      fullWidth
                      maxLength={7}
                      value={record.corpNum2}
                      onChange={(e) => {
                        set_record({ ...record, corpNum2: e })
                      }}
                    />
                  </Grid>
                </Grid>
              }
            />
          ) : (
            <MinwonInput
              label='주민번호'
              must={true}
              component={
                <Grid container style={alignCenter}>
                  <Grid item xs={5}>
                    <NumericTextField
                      fullWidth
                      maxLength={6}
                      value={record.civilNum1}
                      onChange={(e) => {
                        console.log('civilNum1 changed....')
                        set_record({ ...record, civilNum1: e })
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography textAlign={'center'}>-</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <NumericTextField
                      fullWidth
                      maxLength={7}
                      value={record.civilNum2}
                      onChange={(e) => {
                        set_record({ ...record, civilNum2: e })
                      }}
                    />
                  </Grid>
                </Grid>
              }
            />
          )}
          <MinwonInput
            label='전화번호'
            must={true}
            component={
              <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={3}>
                  <TextField fullWidth value={record.phoneDial} disabled={true} />
                </Grid>
                <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography textAlign={'center'}>-</Typography>
                </Grid>
                <Grid item xs={3}>
                  <NumericTextField
                    fullWidth
                    maxLength={4}
                    value={record.phone1}
                    onChange={(e) => {
                      set_record({ ...record, phone1: e })
                    }}
                  />
                </Grid>
                <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography textAlign={'center'}>-</Typography>
                </Grid>
                <Grid item xs>
                  <NumericTextField
                    fullWidth
                    maxLength={4}
                    value={record.phone2}
                    onChange={(e) => {
                      set_record({ ...record, phone2: e })
                    }}
                  />
                </Grid>
              </Grid>
            }
          />
          {record.sepCode === 'S002' || record.sepCode === 'S003' ? (
            <>
              <MinwonInput
                label='상호(법인명)'
                must={true}
                component={
                  <Grid container spacin={1}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        defaultValue={record.companyName}
                        onChange={(e) => {
                          record.companyName = e.target.value
                        }}
                      />
                    </Grid>
                  </Grid>
                }
              />
              <MinwonInput
                label='사업자등록번호'
                must={true}
                component={
                  <Grid container style={{ display: 'flex', alignItems: 'center' }} spacing={1}>
                    <Grid item xs={3}>
                      <NumericTextField
                        fullWidth
                        maxLength={3}
                        value={record.bizNum1}
                        onChange={(e) => {
                          set_record({ ...record, bizNum1: e })
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <NumericTextField
                        fullWidth
                        maxLength={2}
                        value={record.bizNum2}
                        onChange={(e) => {
                          set_record({ ...record, bizNum2: e })
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <NumericTextField
                        fullWidth
                        maxLength={5}
                        value={record.bizNum3}
                        onChange={(e) => {
                          set_record({ ...record, bizNum3: e })
                        }}
                      />
                    </Grid>
                  </Grid>
                }
              />
            </>
          ) : null}
          <MinwonInput
            label='주소'
            must={true}
            component={
              <Grid container spacing={1} style={gs.alignCenter}>
                <Grid item xs={9} md={6}>
                  <TextField fullWidth value={record.addr1} label='기본주소' InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item xs={3} md={3}>
                  <TextField
                    fullWidth
                    label='상세주소'
                    defaultValue={record.addr2}
                    onChange={(e) => {
                      record.addr2 = e.target.value
                    }}
                  />
                </Grid>
                <Grid item xs={12} md>
                  <Button
                    size='md'
                    fullWidth
                    onClick={() => {
                      dgRef.current.open()
                    }}
                  >
                    선택
                  </Button>
                </Grid>
              </Grid>
            }
          />
          {needPrfTerm.includes(docCode) ? (
            <MinwonInput
              label='증명기간'
              must={true}
              component={
                <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                  {docCode === 'DS01' ? (
                    <Grid item xs={12}>
                      <Typography color={theme.palette.secondary.main} style={{ margin: 5 }}>
                        {billiard} 소득금액증명의 경우 신청일기준 당해 7월1일 이후 작년분가능, 이전 전전년까지만가능.
                      </Typography>
                      <Typography color={theme.palette.secondary.main} style={{ margin: 5 }}>
                        {billiard} 발급기간외 신청시 취소처리 됩니다
                      </Typography>
                    </Grid>
                  ) : null}

                  {docCode === 'DS02' ? (
                    <Grid item xs={12}>
                      <Typography color={theme.palette.secondary.main} style={{ margin: 5 }}>
                        {billiard} 부가가치세과세표준증명의 경우 매년 신고일(1월25일 ,7월25일) 후 15일 이후 신청가능.
                      </Typography>
                      <Typography color={theme.palette.secondary.main} style={{ margin: 5 }}>
                        {billiard} 발급기간외 신청시 취소처리 됩니다
                      </Typography>
                    </Grid>
                  ) : null}

                  {docCode === 'DS04' ? (
                    <Grid item xs={12}>
                      <Typography color={theme.palette.secondary.main} style={{ margin: 5 }}>
                        {billiard} 표준재무제표증명의 경우 매년 신고일(법인 3월31일 , 개인 5월51일) 후 한달 이후
                        신청가능.
                      </Typography>
                      <Typography color={theme.palette.secondary.main} style={{ margin: 5 }}>
                        {billiard} 발급기간외 신청시 취소처리 됩니다
                      </Typography>
                    </Grid>
                  ) : null}

                  <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ko'}>
                      <DatePicker
                        label={'시작일'}
                        value={record.prfFrom ?? null}
                        inputFormat='YYYY-MM-DD'
                        onChange={(newValue) => {
                          const dtime = moment(new Date(newValue))
                          const d = dtime.format('YYYY-MM-DD')
                          set_record({ ...record, prfFrom: d })
                        }}
                        renderInput={(params) => <TextField required fullWidth size='small' {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography textAlign={'center'}>-</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ko'}>
                      <DatePicker
                        label={'종료일'}
                        value={record.prfTo ?? null}
                        inputFormat='YYYY-MM-DD'
                        onChange={(newValue) => {
                          const dtime = moment(new Date(newValue))
                          const d = dtime.format('YYYY-MM-DD')
                          set_record({ ...record, prfTo: d })
                        }}
                        renderInput={(params) => <TextField required fullWidth size='small' {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              }
            />
          ) : null}
          {docCode === 'DS06' || docCode === 'DS10' ? (
            <MinwonInput
              label='발급목적'
              must={true}
              component={
                <FormControl fullWidth>
                  <InputLabel id='bid' required>
                    발급목적
                  </InputLabel>
                  <Select
                    required
                    labelId='bid'
                    label='발급목적'
                    value={record.mockCode}
                    onChange={async (e) => {
                      set_record({ ...record, mockCode: e.target.value })
                    }}
                  >
                    {mockCodes.map((l, index) => {
                      return (
                        <MenuItem key={index} value={l.code}>
                          {l.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              }
            />
          ) : null}
          {docCode === 'DS10' ? (
            record.mockCode === 'KL01' ? (
              <MinwonInput
                label='대급지급자'
                must={true}
                component={
                  <TextField
                    fullWidth
                    lbel='대급지급자'
                    value={record.payer}
                    onChange={(e) => {
                      set_record({ ...record, payer: e.target.value })
                    }}
                  />
                }
              />
            ) : record.mockCode === 'KL02' ? (
              <MinwonInput
                label='해외이주정보'
                must={true}
                component={
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      {/* <NumericTextField */}
                      {/*   fullWidth */}
                      {/*   label='이주번호' */}
                      {/*   maxLength={100} */}
                      {/*   value={record.migNum} */}
                      {/*   onChange={(e) => { */}
                      {/*     set_record({ ...record, migNum: e }) */}
                      {/*   }} */}
                      {/* /> */}
                      <TextField
                        fullWidth
                        label='이주번호'
                        defaultValue={record.migNum}
                        inputProps={{
                          maxLength: 20,
                        }}
                        onChange={(e) => {
                          record.migNum = e.target.value
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ko'}>
                        <DatePicker
                          label={'신고일'}
                          value={record.migDate ?? null}
                          inputFormat='YYYY-MM-DD'
                          onChange={(newValue) => {
                            const dtime = moment(new Date(newValue))
                            const d = dtime.format('YYYY-MM-DD')
                            set_record({ ...record, migDate: d })
                          }}
                          renderInput={(params) => <TextField required fullWidth size='small' {...params} />}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                }
              />
            ) : record.mockCode === 'L001' ? (
              <MinwonInput
                label='신탁부동산표시'
                must={true}
                component={
                  <Grid container spacing={1} style={gs.alignCenter}>
                    <Grid item xs={9} md={6}>
                      <TextField
                        fullWidth
                        value={record.trustAddr1}
                        label='기본주소'
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <TextField
                        fullWidth
                        label='상세주소'
                        defaultValue={record.trustAddr2}
                        onChange={(e) => {
                          record.trustAddr2 = e.target.value
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md>
                      <Button
                        size='md'
                        fullWidth
                        onClick={() => {
                          dgRef2.current.open()
                        }}
                      >
                        신탁부동산 주소
                      </Button>
                    </Grid>
                  </Grid>
                }
              />
            ) : null
          ) : null}
          {docCode === 'DS11' ? null : (
            <MinwonInput
              label='용도'
              must={true}
              component={
                <FormControl fullWidth>
                  <InputLabel id='bid' required>
                    용도
                  </InputLabel>
                  <Select
                    required
                    labelId='bid'
                    label='용도'
                    defaultValue={record.aimCode}
                    onChange={async (e) => {
                      console.log('aimCode=>', e.target.value)
                      record.aimCode = e.target.value
                    }}
                  >
                    {aimCodes.map((l, index) => {
                      return (
                        <MenuItem key={index} value={l.code}>
                          {l.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              }
            />
          )}
          {docCode === 'DS09' ? (
            <MinwonInput
              label='사업자등록증 재발급 교부사유'
              must={true}
              component={
                <RadioGroup
                  row
                  value={record.bizDocReason}
                  onChange={(e) => {
                    set_record({ ...record, bizDocReason: e.target.value })
                  }}
                >
                  {['분실', '훼손'].map((reason, idx) => (
                    <FormControlLabel key={idx} value={reason} label={reason} control={<Radio />} />
                  ))}
                </RadioGroup>
              }
            />
          ) : null}
          {docCode === 'DS07' ? (
            <MinwonInput
              label='사업자관련증명'
              must={true}
              component={
                <RadioGroup
                  row
                  value={record.bizCode}
                  onChange={(e) => {
                    set_record({ ...record, bizCode: e.target.value })
                  }}
                >
                  {bizCodes.map((code, idx) => (
                    <FormControlLabel value={code.code} label={code.name} key={idx} control={<Radio />} />
                  ))}
                </RadioGroup>
              }
            />
          ) : null}
          {docCode === 'DS11' ? (
            <MinwonInput
              label='사실확인'
              must={true}
              component={
                <RadioGroup
                  row
                  defaultValue={record.factCode}
                  onChange={(e) => {
                    record.factCode = e.target.value
                  }}
                >
                  {factCodes.map((code, idx) => (
                    <FormControlLabel value={code.code} label={code.name} key={idx} control={<Radio />} />
                  ))}
                </RadioGroup>
              }
            />
          ) : null}

          <MinwonInput
            label='주민등록번호 공개여부'
            must={true}
            component={
              <RadioGroup
                row
                defaultValue={record.civilnumYn}
                onChange={(e) => {
                  record.civilnumYn = e.target.value
                }}
              >
                {[
                  { code: 'Y', name: '공개' },
                  { code: 'N', name: '일부공개' },
                ].map((code, idx) => (
                  <FormControlLabel key={idx} value={code.code} label={code.name} control={<Radio />} />
                ))}
              </RadioGroup>
            }
          />
          <MinwonInput
            label='주소 공개여부'
            must={true}
            component={
              <RadioGroup
                row
                defaultValue={record.addrYn}
                onChange={(e) => {
                  record.addrYn = e.target.value
                }}
              >
                {[
                  { code: 'Y', name: '공개' },
                  { code: 'N', name: '일부공개' },
                ].map((code, idx) => (
                  <FormControlLabel key={idx} value={code.code} label={code.name} control={<Radio />} />
                ))}
              </RadioGroup>
            }
          />
          <MinwonInput
            label='열람/제출 선택'
            must={true}
            component={
              <RadioGroup
                row
                value={record.giveYn}
                onChange={(e) => {
                  set_record({ ...record, giveYn: e.target.value })
                }}
              >
                {[
                  { code: 'N', name: '열람용' },
                  { code: 'Y', name: '제출용' },
                ].map((code, idx) => (
                  <FormControlLabel key={idx} value={code.code} label={code.name} control={<Radio />} />
                ))}
              </RadioGroup>
            }
          />

          <MinwonInput
            label='수량/가격'
            must={true}
            component={
              <Grid container spacing={1} style={gs.alignCenter}>
                <Grid item xs={6} md={2}>
                  <Typography>수량</Typography>
                </Grid>
                <Grid item xs={6} md={4}>
                  <NumericTextField
                    style={{ paddingRight: 100 }}
                    fullWidth
                    maxLength={3}
                    value={record.qty}
                    onChange={(e) => {
                      set_record({ ...record, qty: e })
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  <Typography>금액</Typography>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography>{comma(amount)}</Typography>
                </Grid>
              </Grid>
            }
          />
        </Grid>
      </Container>
    </>
  )
}

export default SemuPartEdit
